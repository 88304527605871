import styled, { css } from "styled-components";

import PlayVideo from "../../../assets/images/play-video.svg";

export const HowVideoLayout = styled.div`
  @media (max-width: 950px) {
    .static-image_video-poster {
      width: calc(100vw - 30px) !important;
      height: 50vh !important;
      min-height: 330px !important;
    }
  }

  @media (max-width: 768px) {
    .static-image_video-poster {
      width: calc(100vw - 30px) !important;
      height: 32vh !important;
      min-height: 330px !important;
    }
  }

  @media (max-width: 500px) {
    .static-image_video-poster {
      width: calc(100vw - 30px) !important;
      height: 200px !important;
      min-height: 200px !important;
    }
  }
`;

export const MainSection = styled.div`
  position: relative;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-row-gap: 12px;
  flex-grow: 1;

  @media (max-width: 950px) {
    min-height: 520px;
  }

  @media (max-width: 768px) {
    min-height: 400px;
  }

  @media (max-width: 500px) {
    min-height: 200px;
  }
`;

export const VideoPoster = styled.div<{ show: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}
`;

export const Video = styled.div`
  position: absolute;
  top: calc(50% + 24px);
  left: 50%;
  transform: translate(-50%, calc(-50% - 12px));

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const PlayVideoButton = styled(PlayVideo)`
  cursor: pointer;

  #hover-circle,
  #hover-polygon {
    display: none;
  }

  :hover {
    #hover-circle,
    #hover-polygon {
      display: flex;
    }
  }

  :active {
    #hover-circle,
    #hover-polygon {
      display: none;
    }
  }
`;

export const VideoPlayer = styled.video<{ show: boolean }>`
  display: none;
  max-height: 550px;
  width: 100%;

  ${(props) =>
    props.show &&
    css`
      display: flex;
    `}
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;
