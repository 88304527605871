import { BigNumberish, ethers } from "ethers";

import { IWowmaxRouter } from "../../../../web3/contracts/IWowmaxRouter";

const MARKETS: Record<string, string> = {
  PANCAKESWAP_V2: "UNISWAP_V2",
  BSC_BI_SWAP: "UNISWAP_V2",
  APESWAP: "UNISWAP_V2",
  BSC_BABYDOGE: "UNISWAP_V2_ROUTER",
  MDEX: "UNISWAP_V2",
  ELLIPSIS_FINANCE: "CURVE",
  BSC_DODO_V2: "DODO_V2"
} as const;

const MAIN_PARTS = 10000;
const SUB_PARTS = 10000;

type OneInchRoute = {
  amount: BigNumberish;
  part: BigNumberish;
  subRoutes: OneInchSubRoute[][];
};

export type OneInchSubRoute = {
  fromTokenAddress: string;
  market: {
    id: string;
    name: string;
  };
  meta: {
    fromTokenAddress: string;
    toTokenAddress: string;
    fee: BigNumberish;
    denominator: string;
    fromTokenAmount: string;
    toTokenAmount: string;
  };
  data?: string;
  part: number;
  toTokenAddress: string;
};

export const BNB_ADDRESS = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
export const BUSD_ADDRESS = "0xe9e7cea3dedca5984780bafc599bd69add087d56";

export const buildRequest = (
  to: string,
  amountOutExpected: string,
  routes: OneInchRoute[]
): IWowmaxRouter.ExchangeRequestStruct => {
  return {
    amountIn: 0,
    from: ethers.constants.AddressZero,
    to: [to],
    exchangeRoutes: routes.map(toExchangeRoutes),
    slippage: [100],
    amountOutExpected: [amountOutExpected]
  };
};

function toExchangeRoutes(route: OneInchRoute): IWowmaxRouter.ExchangeRouteStruct {
  return {
    amountIn: route.amount,
    parts: SUB_PARTS,
    subRoutes: route.subRoutes.map(toExchangeSubRoutes)
  };
}

function toExchangeSubRoutes(subRoutes: OneInchSubRoute[]): IWowmaxRouter.ExchangeSubRouteStruct[] {
  return subRoutes.map((subRoute) => ({
    from: subRoute.fromTokenAddress,
    to: subRoute.toTokenAddress,
    part: subRoute.part,
    addr: subRoute.market.id,
    family: getMarketFamily(subRoute.market.name),
    fee: subRoute.meta.fee,
    feeDenominator: subRoute.meta.denominator,
    data: subRoute.data || ""
  }));
}

function getMarketFamily(name: string): string {
  const family = MARKETS[name];
  if (!family) {
    throw `Invalid market name "${name}"`;
  }
  return ethers.utils.formatBytes32String(family);
}
