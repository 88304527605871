import styled, { css } from "styled-components";

import ArrowSVG from "../../assets/images/compare-arrow.svg";
import Routing from "./routing.svg";
import * as Shared from "./shared.styles";

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1240px;

  @media (max-width: 768px) {
    gap: 12px;
  }
`;

export const Root = styled.div`
  padding: 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  width: 100%;
  max-width: 380px;

  background: linear-gradient(
    293.04deg,
    rgba(190, 5, 255, 0.1) 18.05%,
    rgba(7, 76, 255, 0.1) 99.54%
  );
  border: 1px solid #2d4279;
  box-shadow: 0px 0px 80px rgba(5, 75, 255, 0.6);
  backdrop-filter: blur(10px);

  @media (max-width: 580px) {
    max-width: 1240px;
  }
`;

export const ContentSection = styled.div<{ part: string }>`
  width: 100%;

  padding: 40px 0 0 0;

  ${(props) => props.part === "compare" && "padding-top: 0;"}

  ${(props) =>
    props.part === "compare"
      ? css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `
      : css``}

  ${(props) =>
    props.part === "technology" &&
    css`
      margin-top: 150px;
    `}

  ${(props) =>
    props.part === "how" &&
    css`
      margin-top: 150px;
      margin-bottom: 60px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    `}

  @media (max-width: ${Shared.MediumDevice}) {
    padding: 0;

    ${(props) => props.part === "technology" && "margin-top: 60px;"}
    ${(props) => props.part === "how" && "margin-top: 60px;"}

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 0;

    ${(props) =>
      props.part === "how" &&
      css`
        gap: 20px;
      `}
  }
`;

const NoSpace = css`
  padding: 0;
  margin: 0;
`;

export const PageTitle = styled.h1`
  ${NoSpace}
  text-align: center;
  font-size: 88px;
  line-height: 93px;

  color: ${Shared.WhiteColor};

  @media (max-width: ${Shared.MediumDevice}) {
    font-size: 50px;
    line-height: 53px;

    text-align: center;
  }
`;
export const PageSubtitle = styled.h2`
  ${NoSpace}
  text-align: center;
  font-size: 56px;
  line-height: 100%;

  color: ${Shared.WhiteColor};

  @media (max-width: ${Shared.MediumDevice}) {
    font-size: 28px;
    line-height: 100%;

    text-align: center;
  }
`;
export const ProductDefinition = styled.p`
  ${Shared.BigMediumLabel}
  ${NoSpace}
  padding: 8px;
  text-align: center;
  line-height: 160%;

  color: ${Shared.WhiteColor};

  @media (max-width: ${Shared.MediumDevice}) {
    ${NoSpace}
    padding: 12px;
    padding-bottom: 0;

    font-size: 15px;

    text-align: center;
  }
`;
export const CompareHeadline = styled.div`
  ${Shared.BigMediumLabel}
  ${NoSpace}
  padding: 8px;
  text-align: center;
  line-height: 160%;

  color: ${Shared.GrayColor};

  @media (max-width: ${Shared.MediumDevice}) {
    ${NoSpace}

    font-size: 15px;
    line-height: 160%;

    text-align: center;
  }
`;
export const CompareArrow = styled(ArrowSVG)`
  margin-bottom: 20px;
`;

const text = css`
  font-size: 18px;
  line-height: 140%;
  color: ${Shared.GrayColor};
`;

export const Tech = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: ${Shared.MediumDevice}) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const TechRouting = styled(Routing)`
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;

  svg {
    height: 342px;
  }

  @media (max-width: ${Shared.MediumDevice}) {
    svg {
      height: initial;
    }
  }
`;

export const TechInfoSection = styled.section`
  min-width: 460px;

  display: flex;
  flex-basis: 480px;
  flex-shrink: 1;
  flex-grow: 0;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (max-width: ${Shared.MediumDevice}) {
    flex-basis: 100%;
    min-width: 100%;
  }
`;

export const TechTitle = styled.h2`
  ${NoSpace}
  font-size: 64px;
  line-height: 68px;
  text-transform: uppercase;

  color: ${Shared.WhiteColor};

  @media (max-width: ${Shared.MediumDevice}) {
    text-align: center;
    text-transform: uppercase;

    font-size: 42px;
    line-height: 45px;
  }
`;

export const TechBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  @media (max-width: ${Shared.MediumDevice}) {
    gap: 12px;
  }
`;

export const TechParagraph = styled.p`
  ${NoSpace}
  ${text}

  @media (max-width: ${Shared.MediumDevice}) {
    font-size: 15px;
    line-height: 140%;
  }
`;

export const TechList = styled.ul`
  ${NoSpace}

  @media (max-width: ${Shared.MediumDevice}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const TechItem = styled.li`
  ${text}
  ${NoSpace}

  > span {
    color: ${Shared.WhiteColor};
  }

  position: relative;
  padding-left: 18px;
  list-style-type: none;

  ::before {
    left: 0;
    top: 10px;
    position: absolute;
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background: #d9d9d9;
  }

  @media (max-width: ${Shared.MediumDevice}) {
    font-size: 15px;
    line-height: 100%;

    ::before {
      top: 5px;
    }
  }
`;
export const HowTitle = styled.h2`
  ${NoSpace}
  font-size: 64px;
  line-height: 68px;
  text-transform: uppercase;
  text-align: center;

  color: ${Shared.WhiteColor};

  @media (max-width: ${Shared.MediumDevice}) {
    font-size: 42px;
    line-height: 45px;
  }
`;
