import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";

import * as S from "./styles";

const HowVideo: React.FC = () => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const [isPlayVideo, setIsPlayVideo] = useState(false);

  const playVideo = () => {
    setIsPlayVideo(true);
    videoRef?.current?.play();
  };

  return (
    <S.HowVideoLayout>
      <S.MainSection>
        <S.VideoPoster show={!isPlayVideo}>
          <StaticImage
            className="static-image_video-poster"
            src="../../../assets/images/how-it-work-video-poster.jpg"
            alt="How it works"
            placeholder="blurred"
            layout="fixed"
            width={924}
            height={520}
          />
          <S.Video>
            <S.PlayVideoButton onClick={playVideo} />
          </S.Video>
        </S.VideoPoster>

        <S.VideoContainer>
          <S.VideoPlayer controls show={isPlayVideo} ref={videoRef}>
            <source
              src="https://videodelivery.net/b1499ccda30c7d6a7b6c4edb6cc4e326/downloads/default.mp4"
              type="video/mp4"
            />
          </S.VideoPlayer>
        </S.VideoContainer>
      </S.MainSection>
    </S.HowVideoLayout>
  );
};

export default HowVideo;
