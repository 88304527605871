import { bn } from "@wowswap-io/bignumber";
import axios from "axios";
import { ContractCallResults, Multicall } from "ethereum-multicall";
import { BigNumber } from "ethers";

import { wowmaxPoolsUrl } from "../../../../services";
import { ONE_INCH_PROTOCOLS_BSC_SLIM } from "../../../../services/api/modules/Alpha/constants";
import { DODOV2Pool, OneInchResponse, Reserves } from "../types";
import { getCurveCallData, getCurvePoolData } from "./dex/curve";
import { getDODOV2CallData, getDODOV2PoolData } from "./dex/dodo-v2";
import { CurvePoolRecord, DODOV2PoolRecord, UniswapV2PoolRecord } from "./dex/types";
import { getUniswapV2CallData, getUniswapV2PoolData } from "./dex/uniswap-v2";

const getPools = async (chainId: number, from: string, to: string) => {
  const response = await axios.get<{
    CURVE?: CurvePoolRecord[];
    UNISWAP_V2?: UniswapV2PoolRecord[];
    DODO_V2?: DODOV2PoolRecord[];
  }>(wowmaxPoolsUrl!, {
    params: {
      chainId: chainId,
      from: from,
      to: to
    }
  });
  return response.data;
};

export const loadReserves = async (
  chainId: number,
  from: string,
  to: string,
  multicall: Multicall
) => {
  const pools = await getPools(chainId, from, to);

  const reservesCallData = [
    ...(pools.CURVE || []).map(getCurveCallData),
    ...(pools.UNISWAP_V2 || []).map(getUniswapV2CallData),
    ...(pools.DODO_V2 || []).map(getDODOV2CallData)
  ];

  const result: ContractCallResults = await multicall.call(reservesCallData.flat());

  const reserves: Reserves = {
    UNISWAP_V2: pools.UNISWAP_V2?.map((pool) => getUniswapV2PoolData(pool, result)),
    CURVE: pools.CURVE?.map((pool) => getCurvePoolData(pool, result)),
    DODO_V2: pools.DODO_V2?.map((pool) => getDODOV2PoolData(pool, result)).filter(
      Boolean
    ) as DODOV2Pool[]
  };

  return reserves;
};

export const fetchOneInch = async (gasPrice: BigNumber, value: string) => {
  const response = await axios.get<OneInchResponse>(
    `https://pathfinder.1inch.io/v1.4/chain/56/router/v5/quotes`,
    {
      params: {
        fromTokenAddress: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        toTokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        amount: bn(value).toDecimals(18).str(),
        gasPrice: gasPrice.toString(),
        // protocolWhiteList: ONE_INCH_PROTOCOLS[56],
        protocolWhiteList: ONE_INCH_PROTOCOLS_BSC_SLIM,
        preset: "maxReturnResult"
      }
    }
  );
  return response.data;
};
