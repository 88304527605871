import * as React from "react";

import Arrow from "assets/images/question.svg";

import * as S from "./styles";

type Props = {
  symbol?: string;
  size?: number;
  className?: string;
};

const CoinLogo = ({ symbol, size = 16, className }: Props) => {
  if (!symbol) return <Arrow style={{ width: size, height: size }} className={className} />;

  return (
    <S.CoinLogo
      src={`https://raw.githubusercontent.com/wowswap-io/assets/master/${symbol}.png`}
      size={size}
      className={className}
    />
  );
};

export default CoinLogo;
