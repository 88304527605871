import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { observer } from "mobx-react";

import PageLayout from "components/PageLayout";

import { Compare } from "./Compare";
import HowVideo from "./HowVideo";
import * as S from "./styles";

export const Alpha = observer(() => {
  const { t } = useTranslation();
  return (
    <PageLayout expand>
      <S.MainSection>
        <S.ContentSection part="header">
          <S.PageTitle>{t("WOWMAX")}</S.PageTitle>
          <S.PageSubtitle>{t("always gives you more")}</S.PageSubtitle>
          <S.ProductDefinition>
            {t(
              "We find the best trade routes across multiple markets, including gas fees, to ensure the best rates."
            )}
          </S.ProductDefinition>
        </S.ContentSection>

        <Compare />

        <S.ContentSection part="technology">
          <S.Tech>
            <S.TechRouting />

            <S.TechInfoSection>
              <S.TechTitle>{t("Technology")}</S.TechTitle>

              <S.TechBody>
                <S.TechParagraph>
                  {t(
                    "WOWMAX is the next generation DEX aggregation protocol that uses slippage as an additional source of optimization."
                  )}
                </S.TechParagraph>
                <S.TechParagraph>
                  {t(
                    "We create trading value through both multihop aggregation and same block arbitrage of indirect pairs."
                  )}
                </S.TechParagraph>
                <S.TechParagraph>
                  {t(
                    "WOWMAX exchange protocol brings improvements over key features of the two established DeFi protocols:"
                  )}
                </S.TechParagraph>

                <S.TechList>
                  <S.TechItem>
                    <Trans>
                      <span>Uniswap V2</span> (Multihop feature)
                    </Trans>
                  </S.TechItem>
                  <S.TechItem>
                    <Trans>
                      <span>1inch</span> (Aggregation feature)
                    </Trans>
                  </S.TechItem>
                </S.TechList>
              </S.TechBody>
            </S.TechInfoSection>
          </S.Tech>
        </S.ContentSection>

        <S.ContentSection part="how">
          <S.HowTitle>{t("How it works")}</S.HowTitle>

          <HowVideo />
        </S.ContentSection>
      </S.MainSection>
    </PageLayout>
  );
});
