import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import { Alpha } from "../containers/Alpha";
import pages from "./pages.json";

const AlphaPage = () => {
  return (
    <>
      <Helmet>
        <title>{pages.alpha.title}</title>
      </Helmet>

      <Layout>
        <Alpha />
      </Layout>
    </>
  );
};

export default AlphaPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
