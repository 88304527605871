import { OneInchResponse } from "./types";
import { OneInchSubRoute } from "./utils/request";

export const cutAddress = (address: string) => {
  const length = address.length;
  return `${address.slice(0, 3)}...${address.slice(length - 3, length)}`;
};

export const combineSameDexes = (data: OneInchResponse): OneInchResponse => {
  return {
    ...data,
    bestResult: {
      ...data.bestResult,
      routes: data.bestResult.routes.map((route) => ({
        ...route,
        subRoutes: route.subRoutes.map((subRoute) =>
          subRoute.reduce((acc, el) => {
            const existingElIndex = acc.findIndex((r) => r.market.name === el.market.name);
            if (existingElIndex === -1) {
              acc.push(el);
            } else {
              acc[existingElIndex].part = acc[existingElIndex].part + el.part;
            }
            return acc;
          }, [] as OneInchSubRoute[])
        ) as any // TODO: fix or remove
      }))
    }
  };
};
