import React from "react";

import * as S from "./styles";

type Props = {
  expectedTimeMs: number;
};

export const FakeProgress = ({ expectedTimeMs }: Props) => {
  return (
    <S.Root>
      <S.Line style={{ animationDuration: expectedTimeMs + "ms" }} />
    </S.Root>
  );
};
