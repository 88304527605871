export const DEX_MAP: Record<string, string> = {
  PANCAKESWAP_V2: "Pancake V2",
  BSC_BI_SWAP: "Biswap",
  APESWAP: "Apeswap",
  ELLIPSIS_FINANCE: "Ellipsis",
  MDEX: "MDEX",
  BSC_BABYDOGE: "BabyDoge",
  BSC_DODO_V2: "DODO V2"
};

export const TOKENS_MAP: Record<string, string> = {
  "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee": "BNB",
  "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c": "BNB",
  "0xe9e7cea3dedca5984780bafc599bd69add087d56": "BUSD",
  "0x55d398326f99059ff775485246999027b3197955": "USDT",
  "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c": "BTC",
  "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82": "CAKE",
  "0x2170ed0880ac9a755fd29b2688956bd959f933f8": "ETH",
  "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d": "USDC",
  "0xBb46693eBbEa1aC2070E59B4D043b47e2e095f86": "BFG",
  "0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95": "BANANA",
  "0x9C65AB58d8d978DB963e63f2bfB7121627e3a739": "MDX",
  "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1": "BSW"
};
