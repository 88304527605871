import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  height: 10px;
  background: #0d0935;
  border: 1px solid #122862;
  border-radius: 5px;
`;

export const Line = styled.div`
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: linear-gradient(90deg, #054bff 0%, #be05ff 100%);
  position: relative;
  animation-name: progress;
  animation-fill-mode: forwards;

  @keyframes progress {
    0% {
      width: 0%;
    }
    30% {
      width: 30%;
    }
    35% {
      width: 30%;
    }
    60% {
      width: 60%;
    }
    100% {
      width: 95%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    animation: moving-lines 2s linear infinite;
    overflow: hidden;
  }

  @keyframes moving-lines {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 50px 50px;
    }
  }
`;
