import { bn } from "@wowswap-io/bignumber";
import { ContractCallResults } from "ethereum-multicall";
import { ethers } from "ethers";

import DODOV2_POOL_ABI from "../../../../../constants/abi/dodoV2Pool.json";
import { DODOV2Pool } from "../../types";
import { DODOV2PoolRecord } from "./types";

export const getDODOV2CallData = (pair: DODOV2PoolRecord) => {
  return {
    reference: pair.address,
    contractAddress: pair.address,
    abi: DODOV2_POOL_ABI,
    calls: [
      {
        reference: "pmmState",
        methodName: "getPMMStateForCall",
        methodParameters: []
      },
      {
        reference: "feeRate",
        methodName: "getUserFeeRate",
        methodParameters: [ethers.constants.AddressZero]
      }
    ]
  };
};

export const getDODOV2PoolData = (
  pair: DODOV2PoolRecord,
  data: ContractCallResults
): DODOV2Pool | undefined => {
  try {
    const pairData = data.results[pair.address].callsReturnContext;
    const pmmState = pairData.find((e) => e.reference === "pmmState");
    const feeRate = pairData.find((e) => e.reference === "feeRate");

    return {
      address: pair.address,
      baseToken: pair.tokens[0],
      quoteToken: pair.tokens[1],
      state: {
        i: bn(pmmState?.returnValues[0].hex).str(),
        K: bn(pmmState?.returnValues[1].hex).str(),
        B: bn(pmmState?.returnValues[2].hex).str(),
        Q: bn(pmmState?.returnValues[3].hex).str(),
        B0: bn(pmmState?.returnValues[4].hex).str(),
        Q0: bn(pmmState?.returnValues[5].hex).str(),
        R: bn(pmmState?.returnValues[6].hex).str(),
        lpFeeRate: bn(feeRate?.returnValues[0].hex).str(),
        mtFeeRate: bn(feeRate?.returnValues[1].hex).str()
      }
    };
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
