import { bn } from "@wowswap-io/bignumber";
import { ContractCallContext, ContractCallResults } from "ethereum-multicall";

import ELLIPSIS_PAIR_ABI from "../../../../../constants/abi/ellipsisPair.json";
import { CurvePool } from "../../types";
import { CurvePoolRecord } from "./types";

export const getCurveCallData = (pool: CurvePoolRecord): ContractCallContext => {
  return {
    reference: pool.address,
    contractAddress: pool.address,
    abi: ELLIPSIS_PAIR_ABI,
    calls: [
      {
        reference: "balance0",
        methodName: "balances",
        methodParameters: [0]
      },
      {
        reference: "balance1",
        methodName: "balances",
        methodParameters: [1]
      },
      {
        reference: "balance2",
        methodName: "balances",
        methodParameters: [2]
      },
      {
        reference: "amplifier",
        methodName: "A",
        methodParameters: []
      },
      {
        reference: "feeNumerator",
        methodName: "fee",
        methodParameters: []
      }
    ]
  };
};

export const getCurvePoolData = (pair: CurvePoolRecord, data: ContractCallResults): CurvePool => {
  const pairData = data.results[`${pair.address}`].callsReturnContext;
  return {
    ...pair,
    tokens: pair.tokens,
    info: {
      balances: [
        pairData.find((el) => el.reference === "balance0")?.returnValues[0]?.hex,
        pairData.find((el) => el.reference === "balance1")?.returnValues[0]?.hex,
        pairData.find((el) => el.reference === "balance2")?.returnValues[0]?.hex
      ]
        .filter(Boolean)
        .map((balance) => bn(balance).str()) as string[],
      amplifier: bn(
        pairData.find((el) => el.reference === "amplifier")?.returnValues[0].hex || 0x0
      ).str(),
      feeNumerator: bn(
        pairData.find((el) => el.reference === "feeNumerator")?.returnValues[0].hex || 0x0
      ).str(),
      feeDenominator: String(10 ** 10)
    }
  };
};
