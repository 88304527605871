/* eslint-disable no-console */
import { ChainId, useEthers } from "@usedapp/core";
import { bn } from "@wowswap-io/bignumber";
import axios from "axios";
import { Multicall } from "ethereum-multicall";
import { BigNumber } from "ethers";
import { Fragment, useMemo, useState } from "react";

import Arrow from "assets/images/compare-arrow.svg";
import Button from "components/Button";
import CoinLogo from "components/CoinLogo";
import { FakeProgress } from "components/FakeProgress";
import { CHAINS, MULTICALL_V3_ADDRESS } from "constants/index";
import { wowmaxAggregateUrl } from "services";
import { useStore } from "store";
import { useWowmax, useWowmaxExchange } from "web3/hooks";

import { DEX_MAP, TOKENS_MAP } from "./constants";
import { combineSameDexes, cutAddress } from "./helpers";
import * as S from "./styles";
import { OneInchResponse, Preset } from "./types";
import { fetchOneInch, loadReserves } from "./utils/data";
import { buildRequest, BUSD_ADDRESS } from "./utils/request";

const COMPARE_CHAIN_ID = ChainId.BSC;

export const Compare = () => {
  const { library } = useEthers();

  const [value, setValue] = useState<string>("1000");
  const [oneInchResult, setOneInchResult] = useState<null | OneInchResponse>(null);
  const [isOneInchLoading, setIsOneInchLoading] = useState(false);
  const [isOneInchError, setIsOneInchError] = useState(false);

  const [wowmaxResult, setWowmaxResult] = useState<null | OneInchResponse>(null);
  const [isWowmaxLoading, setIsWowmaxLoading] = useState(false);
  const [isWowmaxError, setIsWowmaxError] = useState(false);

  const { account, switchNetwork, chainId } = useEthers();
  const { walletsModal } = useStore();

  const incorrectChainId = chainId !== COMPARE_CHAIN_ID;
  const wowmax = useWowmax(CHAINS[COMPARE_CHAIN_ID].contracts.wowmax);
  const { send: wowmaxExchange } = useWowmaxExchange(wowmax);

  const clearResults = () => {
    setOneInchResult(null);
    setWowmaxResult(null);
    setIsOneInchLoading(false);
    setIsOneInchError(false);
    setIsWowmaxLoading(false);
    setIsWowmaxError(false);
  };

  const handleChainSwitch = () => {
    switchNetwork(COMPARE_CHAIN_ID);
  };

  const loadOneInch = async () => {
    if (!library || !value || +value < 0) return;
    setIsOneInchLoading(true);
    try {
      const result = await fetchOneInch(await library.getGasPrice(), value);
      console.log("1inch", result);
      setOneInchResult(result);
    } catch (e) {
      console.log(e);
      setIsOneInchError(true);
    } finally {
      setIsOneInchLoading(false);
    }
  };

  const fetchWowmax = async (preset?: string) => {
    if (!library || !value || +value < 0 || !wowmaxAggregateUrl) return;
    setIsWowmaxLoading(true);
    try {
      const gasPrice = await library.getGasPrice();
      const reserves = await loadReserves(
        COMPARE_CHAIN_ID,
        "BNB",
        "BUSD",
        new Multicall({
          nodeUrl: "https://nodes-binance-https.wowswap.io",
          multicallCustomContractAddress: MULTICALL_V3_ADDRESS,
          tryAggregate: true
        })
      );

      const response = await axios.post<OneInchResponse>(wowmaxAggregateUrl, {
        amount: value,
        preset,
        gasPrice: gasPrice.toString(),
        reserves: reserves
      });

      console.log("wowmaxResult", response.data);
      setWowmaxResult(response.data);
    } catch (e) {
      console.log(e);
      setIsWowmaxError(true);
    } finally {
      setIsWowmaxLoading(false);
    }
  };

  const calculate = (preset: Preset) => {
    clearResults();
    loadOneInch();
    fetchWowmax(preset);
  };

  const exchange = async () => {
    if (!wowmaxResult) return;
    const request = buildRequest(
      BUSD_ADDRESS,
      wowmaxResult.bestResult.toTokenAmount,
      wowmaxResult.bestResult.routes
    );
    console.log("wowmax request", request);
    await wowmaxExchange(request, {
      value: request.exchangeRoutes
        .map((r) => BigNumber.from(r.amountIn))
        .reduce((a, b) => a.add(b))
    });
  };

  const viewOneInchResult = useMemo(() => {
    if (!oneInchResult) return null;
    return combineSameDexes(oneInchResult);
  }, [oneInchResult]);

  const viewWowmaxResult = useMemo(() => {
    if (!wowmaxResult) return null;
    return combineSameDexes(wowmaxResult);
  }, [wowmaxResult]);

  return (
    <S.Root>
      <h1>
        Compare <br />
        1inch and WOWMAX
      </h1>

      <S.FormLine>
        <S.DexBlock>
          <S.DexLogo>
            <img src="/images/dexs/1INCH.png" />
          </S.DexLogo>
          {viewOneInchResult && (
            <S.Result>
              <CoinLogo size={26} symbol="BUSD" />
              <span>
                {bn(viewOneInchResult.bestResult.toTokenAmount).fromDecimals(18).toFixed(2)} BUSD
              </span>
            </S.Result>
          )}
        </S.DexBlock>

        <S.Form>
          <S.FormCoins>
            <CoinLogo size={22} symbol="BNB" /> BNB
            <S.FormArrow>
              <Arrow />
            </S.FormArrow>
            <CoinLogo size={22} symbol="BUSD" /> BUSD
          </S.FormCoins>
          <S.Label>Amount</S.Label>
          <S.Input value={value} onChange={(e) => setValue(e.target.value)} />
          <S.FormButtons>
            {(
              [
                { caption: "to BUSD", name: "busd", icons: ["BUSD"] }
                // { caption: "to Stable", name: "stable", icons: ["BUSD", "USDT", "USDC"] },
                // { caption: "Go Nuts!", name: "test", icons: ["WOW"] }
              ] as const
            ).map(({ caption, name, icons }) => (
              <Button key={name} onClick={() => calculate(caption)}>
                {caption}{" "}
                {icons.map((icon, i) => (
                  <CoinLogo key={i} size={26} symbol={icon} />
                ))}
              </Button>
            ))}
          </S.FormButtons>
        </S.Form>
        <S.DexBlock>
          <S.DexLogo>
            <img src="/images/dexs/WOWMAX.png" />
          </S.DexLogo>
          {viewWowmaxResult && (
            <S.Result>
              <CoinLogo size={26} symbol="BUSD" />
              <span>
                {bn(viewWowmaxResult.bestResult.toTokenAmount).fromDecimals(18).toFixed(2)} BUSD
              </span>
              <div style={{ marginLeft: "5px" }}>
                {!account && <Button onClick={() => walletsModal.open()}>Connect wallet</Button>}
                {account && !incorrectChainId && <Button onClick={() => exchange()}>Swap</Button>}
                {account && incorrectChainId && (
                  <Button onClick={handleChainSwitch}>Switch Network</Button>
                )}
              </div>
            </S.Result>
          )}
        </S.DexBlock>
      </S.FormLine>

      <S.Blocks>
        <S.Block position="left">
          {isOneInchLoading && (
            <S.Progress>
              <FakeProgress expectedTimeMs={3000} />
            </S.Progress>
          )}
          {isOneInchError && <S.Error>⚠ Sorry, an error has occurred. Please try again </S.Error>}
          {viewOneInchResult && (
            <S.Routing>
              <CoinLogo size={40} symbol="BNB" />
              <S.RoutingLine />
              <S.Routes>
                {viewOneInchResult.bestResult.routes.map((route, i) => {
                  const mainParts = viewOneInchResult.bestResult.routes.reduce(
                    (acc, r) => acc + r.part,
                    0
                  );
                  return (
                    <S.Route key={i}>
                      <S.RoutePercent>
                        {bn(route.part).div(mainParts).mul(100).toFixed(0)}%
                      </S.RoutePercent>
                      <S.RouteArrow />
                      {route.subRoutes.map((subRoute, i: number) => {
                        const address = subRoute[0].toTokenAddress;
                        const symbol = TOKENS_MAP[address];

                        return (
                          <Fragment key={i}>
                            <S.SubRoute>
                              <S.SubRouteToken>
                                <CoinLogo size={26} symbol={symbol} />
                                {symbol || (
                                  <a href={`https://bscscan.com/address/${address}`}>
                                    {cutAddress(address)}
                                  </a>
                                )}
                              </S.SubRouteToken>
                              <S.Dexes>
                                {subRoute.map((r, i) => {
                                  const subParts = subRoute.reduce((acc, r) => acc + r.part, 0);
                                  return (
                                    <S.Dex key={i}>
                                      {DEX_MAP[r.market.name]}{" "}
                                      {bn(r.part).div(subParts).mul(100).toFixed()}%
                                    </S.Dex>
                                  );
                                })}
                              </S.Dexes>
                            </S.SubRoute>
                            <S.RouteArrow />
                          </Fragment>
                        );
                      })}
                    </S.Route>
                  );
                })}
              </S.Routes>
              <S.RoutingLine />
              <CoinLogo size={40} symbol="BUSD" />
            </S.Routing>
          )}
        </S.Block>

        <S.Block position="right">
          {isWowmaxLoading && (
            <S.Progress>
              <FakeProgress expectedTimeMs={9000} />
            </S.Progress>
          )}
          {isWowmaxError && <S.Error>⚠ Sorry, an error has occurred. Please try again </S.Error>}
          {viewWowmaxResult && (
            <S.Routing>
              <CoinLogo size={40} symbol="BNB" />
              <S.RoutingLine />
              <S.Routes>
                {viewWowmaxResult?.bestResult.routes.map((route, i) => {
                  return (
                    <S.Route key={i}>
                      <S.RoutePercent>
                        {+bn(route.part).div(viewWowmaxResult.preset.mainParts).mul(100).toFixed(1)}
                        %
                      </S.RoutePercent>
                      <S.RouteArrow />
                      {route.subRoutes.map((subRoute, i: number) => {
                        const address = subRoute[0].toTokenAddress;
                        const symbol = TOKENS_MAP[address];

                        return (
                          <Fragment key={i}>
                            <S.SubRoute>
                              <S.SubRouteToken>
                                <CoinLogo size={26} symbol={symbol} />
                                {symbol || (
                                  <a href={`https://bscscan.com/address/${address}`}>
                                    {cutAddress(address)}
                                  </a>
                                )}
                              </S.SubRouteToken>
                              <S.Dexes>
                                {subRoute.map((r, i) => (
                                  <S.Dex key={i}>
                                    {DEX_MAP[r.market.name]}{" "}
                                    {
                                      +bn(r.part)
                                        .div(viewWowmaxResult.preset.subParts)
                                        .mul(100)
                                        .toFixed(1)
                                    }
                                    %
                                  </S.Dex>
                                ))}
                              </S.Dexes>
                            </S.SubRoute>
                            <S.RouteArrow />
                          </Fragment>
                        );
                      })}
                    </S.Route>
                  );
                })}
              </S.Routes>
              <S.RoutingLine />
              <CoinLogo size={40} symbol="BUSD" />
            </S.Routing>
          )}
        </S.Block>
      </S.Blocks>
    </S.Root>
  );
};
