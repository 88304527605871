import { css } from "styled-components";

export const MediumDevice = "1024px";

export const GrayColor = "#90A3DE";
export const Gray2Color = "#2D4279";
export const WhiteColor = "#FFFFFF";
export const RedColor = "#FC3C98";
export const YellowColor = "#F3BA2F";

export const BestScoreColor = "#0DE37D";
export const GreatScoreColor = "#F3BA2F";
export const WorstScoreColor = "#FC3C98";

export const GreatLossColor = "#BF8800";
export const WortLossColor = "#970303";

export const BiggestBoldLabel = css`
  font-size: 22px;
  line-height: 24px;
  font-weight: bold;
`;

export const BigMediumLabel = css`
  font-size: 18px;
  line-height: 100%;
`;

export const MiddleMediumLabel = css`
  font-size: 15px;
  line-height: 100%;
`;

export const MiddleBoldLabel = css`
  font-size: 15px;
  line-height: 100%;
  font-weight: bold;
`;
