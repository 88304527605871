import { bn } from "@wowswap-io/bignumber";
import { ContractCallResults } from "ethereum-multicall";

import PAIR_ABI from "../../../../../constants/abi/pair.json";
import { UniswapV2Pool } from "../../types";
import { UniswapV2PoolRecord } from "./types";

export const getUniswapV2CallData = (pool: UniswapV2PoolRecord) => {
  return {
    reference: pool.address,
    contractAddress: pool.address,
    abi: PAIR_ABI,
    calls: [
      {
        reference: `getReserves_${pool.address}`,
        methodName: "getReserves",
        methodParameters: []
      }
    ]
  };
};

export const getUniswapV2PoolData = (
  pool: UniswapV2PoolRecord,
  data: ContractCallResults
): UniswapV2Pool => {
  const tokens = pool.tokens.sort((p1, p2) => (p1.address > p2.address ? 1 : -1));
  const poolReserves = data.results[pool.address].callsReturnContext[0].returnValues;
  if (poolReserves.length === 0) {
    console.log("Error in pair: ", pool);
  }
  return {
    ...pool,
    tokens: tokens.map((token, i) => ({ ...token, reserve: bn(poolReserves[i].hex).str() }))
  };
};
