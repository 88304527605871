import styled, { css } from "styled-components";

import ArrowRight from "assets/images/arrow-right-slim.svg";
import Card from "components/Card";

export const Root = styled.div`
  width: 100%;
  margin: 150px 0px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  h1 {
    font-size: 56px;
    text-align: center;
  }
`;

export const FormLine = styled.div`
  width: 100%;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
`;

export const Form = styled.div`
  width: 100%;
  max-width: 460px;
  margin-bottom: 70px;
  padding: 30px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: linear-gradient(
    293.04deg,
    rgba(190, 5, 255, 0.1) 18.05%,
    rgba(7, 76, 255, 0.1) 99.54%
  );
  border: 1px solid #2d4279;
`;

export const FormCoins = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  color: #90a3de;
  font-weight: bold;
`;

export const FormArrow = styled.div`
  height: 0px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  transform: rotate(-90deg);
`;

export const Label = styled.div`
  margin-bottom: 5px;
  font-size: 15px;
  color: #90a3de;
`;

export const Input = styled.input`
  margin-bottom: 20px;
  padding: 14px 15px 11px;
  background: #0d0935;
  border: 1px solid #122862;
  font-size: 24px;
  font-weight: bold;
  color: white;
`;

export const FormButtons = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const DexBlock = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DexLogo = styled.div`
  margin-bottom: 40px;

  img {
    filter: drop-shadow(0px 4px 30px rgba(190, 5, 255, 0.8));
  }
`;

export const Result = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
    padding-top: 6px;
    font-size: 24px;
    font-weight: bold;
  }
`;

export const Blocks = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const Block = styled.div<{ position: "left" | "right" }>`
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.position === "left"
      ? css`
          align-items: flex-start;
        `
      : css`
          align-items: flex-end;
        `}
`;

export const Routing = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const RoutingLine = styled.div`
  margin: 0px 10px;
  width: 1px;
  height: 100%;
  background: linear-gradient(transparent, #90a3de, transparent);
`;

export const Routes = styled.div`
  flex-grow: 1;
`;

export const Route = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const RoutePercent = styled.div`
  padding: 5px 8px 3px;
  margin-right: 5px;
  background: #0d0935;
  border: 1px solid #122862;
`;

export const RouteArrow = styled(ArrowRight)`
  width: 7px;
  height: 10px;

  path {
    stroke: #90a3de;
  }
`;

export const SubRoute = styled(Card)`
  margin: 10px;
  padding: 10px;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const SubRouteToken = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px 0px;
  font-weight: bold;
  font-size: 18px;

  a {
    color: #9c70c5;
    font-size: 16px;
  }
`;

export const Dexes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Dex = styled.div`
  flex-grow: 1;
  text-align: center;
  padding: 10px 8px 8px;
  border-radius: 5px;
  background: linear-gradient(
    293.04deg,
    rgba(190, 5, 255, 0.1) 18.05%,
    rgba(7, 76, 255, 0.1) 99.54%
  );
  color: #90a3de;
`;

export const Dot = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 20px;
`;

export const Error = styled.div`
  margin-bottom: auto;
  font-size: 18px;
`;

export const Progress = styled.div`
  margin-bottom: auto;
  width: 70%;
`;
